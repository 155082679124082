/*
Buttons
Buttons
Buttons
Buttons
*/
.button {
	cursor:pointer;
}
	
.button {
	cursor:pointer;
}
	
.button-1 {
	float:left;
	background-color:#f26866;
	color:#fff;
	padding:13px;
	padding-left:20px;
	padding-right:20px;
	border-radius:5px;
	border-bottom:3px solid #613736;
}

	.button-1.purple {
		background-color:#531b66;
	}

	.button-1:hover {
		margin-top:2px;
		border-bottom:1px solid #613736;
		transition: 0.5s ease-in-out;
	}
	
	.button-1.big {
		padding:20px;
		padding-left:30px;
		padding-right:30px;
	}
	
		.button-1.big .label {
			font-size:25px;
			line-height:25px;
		}	

	.button-1.inline {
		display:inline-block;
		float:none;
	}

	.button-1:hover > .label {
		color:#fff;
		text-decoration:underline;
	}

	.button-1 .icon {
		float:left;
		margin-top:0px;
		font-size:26px;
		color:#000;
	}
		.button-1 .icon.small {
			margin-top:5px;
			font-size:17px;
		}
		.button-1.lm {
			margin-left:4px;
		}
		.button-1.lm .label {
			margin-left:14px;
		}
		.button-1 .icon.right {
			margin-left:10px;
		}
		.button-1 .icon.left {
			margin-right:10px;
		}
	
	.button-1 .inline {
		display:inline-block;
	}
	
	.button-1 .label {
		font-family:'Open Sans',arial;
		float:left;
		font-size:18px;
		line-height:15px;
		color:#fff;
		font-weight:bold;
		letter-spacing:0px;
		text-decoration: none;
	}
	
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.button-1 {
		padding:13px;
	}
	
	.button-1 .label {
		font-size:15px;
	}
	
	.button-1.big {
		padding:10px;
		padding-left:20px;
		padding-right:20px;
	}
	
		.button-1.big .label {
			font-size:20px;
			line-height:20px;
		}	
	
}
