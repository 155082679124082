/*
Colors
Colors
Colors
Colors
*/
.color-white {
	color:#fff !important;
}
.color-black {
	color:#000 !important;
}

.color-blue {
	color:#14408a !important;
}
.color-orange {
	color:#e74011 !important;
}
.color-green {
	color:#d3ce0c !important;
}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
}
