.include_team {
	float:left;
	width:100%;
	margin-top:40px;
	margin-bottom:40px;
}

	.include_team .person {
		float:left;
		width:80%;
	}

		.include_team .person .image {
			float:left;
			width:100%;
			padding-top:100%;
			border-radius:50%;
			background-color:#e1e1e1;
			border:2px solid #fff;
			box-shadow:0 0 20px rgba(0,0,0,0.4);
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
		}

		.include_team .person h2 {
			display:inline-block;
			width:100%;
			padding:0;
			margin:0;
			margin-top:20px;
			font-size:20px;
			color:#000;
			text-align:center;
		}

		.include_team .person h3 {
			display:inline-block;
			width:100%;
			padding:0;
			margin:0;
			margin-top:5px;
			font-size:20px;
			font-weight:normal;
			color:#000;
			text-align:center;
		}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.include_team .person {
		float:none;
		display:inline-block;
		width:60%;
	}

		.include_team .person .image {
			float:left;
			width:100%;
			margin-top:20px;
			padding-top:100%;
			border-radius:50%;
			background-color:#e1e1e1;
			border:2px solid #fff;
			box-shadow:0 0 20px rgba(0,0,0,0.4);
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
		}

		.include_team .person h2 {
			display:inline-block;
			width:100%;
			padding:0;
			margin:0;
			margin-top:20px;
			font-size:15px;
			color:#000;
			text-align:center;
		}

		.include_team .person h3 {
			display:inline-block;
			width:100%;
			padding:0;
			margin:0;
			margin-top:5px;
			font-size:15px;
			font-weight:normal;
			color:#000;
			text-align:center;
		}
	
}
