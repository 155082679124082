.page.content--landingspagina {
	margin-top:70px;
}

	.first {
		float:left;
		width:100%;
	}
	
	.first video {
		float:left;
		width:100%;
	}
	
	.page-marge .mt-80 {
		margin-top:0px;
	}


	.portfolio-box {
		position:relative;
		float:left;
		width:100%;
		border-radius:20px;
		border:1px solid #ccc;
		overflow:hidden;
	}

	.portfolio-box .video {
		float:left;
		width:100%;
		height:27.5vw;
		background-color:#ccc;
	}

		.portfolio-box .video video {
			float:left;
			width:100%;
			height:100%;
			object-fit:cover;
		}

	.portfolio-box .info {
		float:left;
		width:100%;
		padding:20px;
		background-color:#531b66;
		text-align:center;
	}

	.portfolio-box .info h3 {
		display:inline-block;
		padding:0;
		margin:0;
		color:#fff;
	}

	.portfolio-box .info h2 {
		display:inline-block;
		padding:0;
		margin:0;
		margin-top:5px;
		color:#fff;
	}

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	

	.portfolio-box .video {
		height:44vw;
	}

	.portfolio-box .info {
		padding:10px;
		padding-bottom:15px;
	}

	.portfolio-box .info h3 {
		font-size:12px;
	}

	.portfolio-box .info h2 {
		font-size:20px;
	}

}