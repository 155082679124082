body {
	font-family:'Open Sans',arial;
	background-color:#fff;
}

Selection
**/
::selection {
	background:#000;
	color:#fff;
}

/**
Marges
**/
.page-marge {
	float:left;
	width:100%;
	margin-top:120px;
	padding-bottom:40px;
}
	
/* GLOBAL ITEMS */
/* GLOBAL ITEMS */
/* GLOBAL ITEMS */
/* GLOBAL ITEMS */

.slideshow {
		float:left;
		width:100%;
		margin-bottom:80px;
	}

	
		 .slideshow .swiper-slide.width100 {
			 width:40%;
		 }
		 .slideshow .swiper-slide.width50 {
			 width:20%;
		 }
	
		.slideshow .case {
		
			float:left;
			width:100%;
			height:650px;
			background-size:contain;
			background-repeat:no-repeat;
			background-position: center;
			object-fit: cover;
			background-color:#fff;
		}
		
			.slideshow .case video {
				float:left;
				width:100%;
				height:650px;
				object-fit: contain;
			}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {

	.slideshow {
		margin-top:0;
		margin-bottom:20px;
	}
	
	.slideshow .case {
		height:100px;
	}
	
		.slideshow .case video {
			height:100px;
		}

}
