.popup--demo {
	width:80vw;
}

	.popup--demo .header {
		float:left;
		padding:10px;
		padding-left:20px;
		padding-right:20px;
		background-color:#2b1832;
		text-align:left;
		color:#fff;
	}
	
		.popup--demo .header h2 {
			color:#fff;
			padding:0;
			margin:0;
		}

	.popup--demo .content {
		float:left;
		background-color:#fff;
		padding:20px;
		text-align:left;
	}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.popup--demo {
		width:280px;
	}
	
}