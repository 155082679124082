.form {
	float:left;
	color:#000;
	width:100% !important;
}

.form a {
	color:#000;
}

.form .form-row .label {
	color:#000;
	margin-bottom:5px;
	float:left;
	width:100%;
	font-size:14px;
}

.form .element-row .indicator {
	position:absolute;
	top:0;
	right:0;
	height:100%;
	width:2px;
	background-color:#000;
}

	.form .element-row label {
		float:left;
		font-size:16px;
		color:#000;
		font-weight:bold;
		margin-bottom:5px;
		margin-top:5px;
	}
	
	.form .element-row .form-input {
		padding:10px 15px;
		box-shadow: inset 0px 0px 7px -4px rgba(0,0,0,1);
		border:1px solid #888;
	}
	
	.form .element-row .upload .left {
		width:200px;
		min-height:109px;
		background-color:#e1e1e1;
		border-radius:4px;
		text-align:center;
	}
	
		.form .upload-info {
			float:left;
			width:100%;
			text-align:center;
			font-size:13px;
		}
	
	.form .element-row .indicator.ok {
		background-color:#84b50f;
	}
	
	.form .form-submit {
		float:right;
		margin:0;
		text-transform:uppercase;
		transition: all 0.1s ease-in-out;
		background-color:#000;
		color:#fff;
		cursor:pointer;
		
		margin-top:20px;
		padding-top:14px;
		padding-bottom:10px;
		padding-left:20px;
		padding-right:20px;
		font-family:'Poppins',arial;
		font-size:20px;
		line-height:20px;
		background-color:#639a0b;
		border-radius:60px;

		
	}
	
	.form .form-cancel {
		float:right;
		margin:0;
		margin-top:10px;
		margin-right:5px;
		padding:10px 15px;
		padding-right:17px;
		font-size:18px;
		font-weight:bold;
		text-transform:uppercase;
		font-family:'Open Sans',arial;
		transition: all 0.1s ease-in-out;
		background-color:#cdcdcd;
		color:#fff;
		cursor:pointer;
	}
	
	.form .form-submit:hover,
	.form .form-cancel:hover {
		text-decoration:underline;
	}
	
.thanks {
	float:left;
	width:100%;
	font-size:20px;
	padding:20px;
	margin-top:20px;
	margin-bottom:20px;
	background-color:#f1f1f1;
	font-family:'Open Sans',arial;
	color:#000;
	text-align:center;
	border:1px solid #ccc;
}

	.thanks .textblock-1 {
		font-size:20px;
		line-height:30px;
	}


/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.form {
		float:left;
		color:#000;
		width:100% !important;
	}
	
}
