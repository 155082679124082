.popup--interesse {
	width:500px;
}

	.popup--interesse .header {
		float:left;
		padding:10px;
		padding-left:20px;
		padding-right:20px;
		background-color:#2b1832;
		text-align:left;
		color:#fff;
	}
	
		.popup--interesse .header h2 {
			color:#fff;
			padding:0;
			margin:0;
		}

	.popup--interesse .content {
		float:left;
		background-color:#fff;
		padding:20px;
		text-align:left;
	}
	
		.popup--interesse .content  .textblock-2 p {
			font-size:16px;
			line-height:30px;
		}
	
	.popup--interesse .calendly-inline-widget {
			float:left;
			min-width:100%;
			height:450px;
		};
				
		.popup--interesse .calendly-inline-widget .spotpicker .spotpicker-dates {
			padding:0;
		}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.popup--interesse {
		width:280px;
	}
	
}