.slideshow-clients {
	float:left;
	width:100%;
	margin-top:40px;
	margin-bottom:40px;
}

	.slideshow-clients .logo {
		float:left;
		width:100%;
		height:200px;
		background-repeat:no-repeat;
		background-size: contain;
		background-position: center;
	}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.slideshow-clients .logo {
		height:70px;
	}
	
	.slideshow-clients {
		margin-top:20px;
	}
	
}
