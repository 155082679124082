.page.content--portfolio-item {
	margin-top:70px;
}

	.page.content--portfolio-item iframe {
		float:left;
		margin-top:40px;
		width:100%;
		height:100vh;
		box-shadow:0px -20px 40px rgba(0, 0, 0, 0.1),0px 20px  40px  rgba(0, 0, 0, 0.1);
		border-left:0;
		border-right:0;
	}
	
	.page.content--portfolio-item .check  {
		margin-top:40px;
	}

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	


}