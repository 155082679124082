/**
Fonts
Fonts
Fonts
Fonts
**/
.font-opensans {
	font-family:'Open Sans',arial;
}

/**
Textblocks
Textblocks
Textblocks
Textblocks
**/
.textblock-1 {
	float:left;
	position:relative;
	width:100%;
	font-family:'Open Sans',arial;
	font-size:1.5vw;
	line-height:2.4vw;
	color:#000;
}
	
	.textblock-1 a {
		color:#000;
		text-decoration:underline;
	}
	
	.textblock-1 h1 {
		display:inline-block;
		font-family:'Open Sans',arial;
		font-size:50px;
		line-height:50px;
		font-weight:normal;
		margin:0;
		padding:0;
		color:#f26866;
		letter-spacing:0;
	}

	.textblock-1 h2 {
		display:inline-block;
		font-family:'Open Sans',arial;
		font-size:30px;
		line-height:30px;
		font-weight:bold;
		margin:0;
		padding:0;
		color:#531b66;
		letter-spacing:0;
	}

	.textblock-1 h4 {
		display:inline-block;
		font-family:'Open Sans',arial;
		font-size:20px;
		line-height:20px;
		font-weight:bold;
		margin:0;
		padding:0;
		color:#f26866;
		letter-spacing:0;
	}
	
	.textblock-1 ul {
		padding:0;
	}
	
	.textblock-1 ul li {
		display:inline-block;
		list-style-type:none;
		color:#f26866;
		font-size:25px;
		line-height:40px;
		font-weight:bold;
	}
	.textblock-1 ul li span.icon {
		display:inline-block;
		float:left;
		position:relative;
		margin-right:20px;
		margin-top:5px;
		margin-left:10px;
		width:30px;
		height:30px;
		border-radius:50%;
		background-color:#531b66;
	}
	
		.textblock-1 ul li span.plus {
			position:absolute;
			top:-6px;
			left:6px;
			font-size:30px;
			color:#fff;
			font-weight:bold;
		}
		
.textblock-2 {
	float:left;
	position:relative;
	width:100%;
	font-family:'Open Sans',arial;
	font-size: 20px;
	line-height: 35px;
	color:#000;
}
	
	.textblock-2 a {
		color:#000;
		text-decoration:underline;
	}
	
	.textblock-2 p {
		padding:0;
		margin:0;
	}
	
	.textblock-2 h1 {
		display:inline-block;
		font-family:'Open Sans',arial;
		font-size:50px;
		line-height:50px;
		font-weight:normal;
		margin:0;
		padding:0;
		color:#f26866;
		letter-spacing:0;
	}

	.textblock-2 h2 {
		display:inline-block;
		font-family:'Open Sans',arial;
		font-size:30px;
		line-height:40px;
		font-weight:bold;
		margin:0;
		padding:0;
		color:#531b66;
		letter-spacing:0;
	}
	
	.textblock-2 h3 {
		display:inline-block;
		font-family:'Open Sans',arial;
		font-size:20px;
		line-height:30px;
		font-weight:bold;
		margin:0;
		padding:0;
		color:#f26866;
		letter-spacing:0;
	}

/*
Lists
*/	
	ul.list1 {
		padding:0;
	}
	
	ul.list1 li {
		display:inline-block;
		list-style-type:none;
		color:#f26866;
		font-size:25px;
		line-height:40px;
		font-weight:bold;
	}
	ul.list1 li span.icon {
		display:inline-block;
		float:left;
		position:relative;
		margin-right:20px;
		margin-top:5px;
		margin-left:10px;
		width:30px;
		height:30px;
		border-radius:50%;
		background-color:#531b66;
	}
	
		ul.list1 li span.plus {
			position:absolute;
			top:-6px;
			left:6px;
			font-size:30px;
			color:#fff;
			font-weight:bold;
		}
		
	ul.list2 {
		padding:0;
		list-style-type:disc;
		list-style-position:inside;
	}
	
	ul.list2 li {
		list-style-type:disc;
		color:#000;
		font-size: 20px;
		line-height: 35px;
		font-weight:normal;
	}
	
		
	

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
	.textblock-1 {
		font-size:14px;
		line-height:22px;
	}
	
		.textblock-1 h1 {
			font-size:27px;
		}
	
		.textblock-1 h2 {
			font-size:27px;
		}
		
		.textblock-1 ul li {
			font-size:14px;
			line-height:20px;
		}
		
		.textblock-1 ul li span.plus {
			font-size:20px;
			top: -1px;
			left: 4px;
		}
		
		.textblock-1 ul li span.icon {
			width:20px;
			height:20px;
			margin-right:10px;
		}
		
	.textblock-2 {
		font-size:14px;
		line-height:22px;
	}
	
		.textblock-2 h1 {
			font-size:27px;
			line-height:30px;
		}
	
		.textblock-2 h2 {
			font-size:20px;
			line-height:15px;
			margin-bottom:20px;
		}
		
		.textblock-2 ul li {
			font-size:14px;
		}
		
		.textblock-2 ul li span.plus {
			font-size:20px;
			top: -11px;
			left: 4px;
		}
		
		.textblock-2 ul li span.icon {
			width:20px;
			height:20px;
			margin-right:10px;
		}
		
		.textblock-2  ul.list2 li {
			font-size:14px;
			line-height:22px;
		}
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	

	.textblock-1 h4 { 
		font-size: 12px;
	}
	
}
