.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	padding:10px;
	background-color:#CAB7E4;
	z-index:4000;
	box-shadow:0 0 10px 10px rgba(0,0,0,0.5);
}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
		
}