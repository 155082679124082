.page.content--home {
	margin-top:70px;
}

	.page.content--home .intro {
		/* margin-left:40px; */
	}

	.card {
		float: left;
		width: 100%;
		background-color: #fff;
		border:1px solid #ccc;
		color: #000;
		padding: 25px;
		text-align: center;
		border-radius: 10px;
	}

		.card .title {
			float: left;
			width: 100%;
			font-size: 20px; 
			font-weight: bold;
		}
		.card .text {
			float: left;
			width: 100%;
			margin-top: 15px;
			font-size: 15px; 
			font-weight: normal;
			line-height: 25px;
		}

	.page.content--home .row.mt-80 {
		margin-top:80px !important;
	}

	.page.content--home .eyes-1 {
		right:0;
		top:0px;
		position:absolute;
		width:100%;
		height:500px;
		overflow:hidden;
	}
	
		.page.content--home .eyes-1 .eye {
			position:absolute;
			width:10vw;
			height:10vw;
			background-size:contain;
		}
		
			.page.content--home .eyes-1 .eye.eye-1 {
				left:40px;
				top:40px;
				transform:rotate(120deg);
				background-image:url("../img/static/eye-animate-1.gif");
			}
			
			.page.content--home .eyes-1 .eye.eye-2 {
				left:210px;
				top:310px;
				background-image:url("../img/static/eye-animate-2.gif");
			}
			
			.page.content--home .eyes-1 .eye.eye-3 {
				left:500px;
				top:100px;
				transform:rotate(-120deg);
				background-image:url("../img/static/eye-animate-3.gif");
			}
		
@media (max-width: 1200px) {

	.page.content--home .slideshow .case,
	.page.content--home .slideshow .case video {
		height:320px;
	}
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	

	.page.content--home .intro {
		margin-left:0;
	}
		
	.page.content--home .eyes-1 .eye.eye-2 {
		top:120px;
	}
	
	.page.content--home .eyes-1 .eye {
		height:50px;
		width:50px;
	}
	
	.page.content--home .row.mt-80 {
		margin-top:20px !important;
	}

	.page.content--home .textblock-1.align-right {
		margin-top:20px;
		text-align:left !important;
	}
	
	.page.content--home .slideshow .case {
		width:270px;
		height:240px;
	}
	
		.page.content--home .slideshow .case video {
			height:240px;
		}


	.card {
		float: left;
		width: 100%;
		background-color: #fff;
		border:1px solid #ccc;
		color: #000;
		padding: 15px;
		text-align: center;
		border-radius: 10px;
		margin-bottom: 10px;
	}

		.card .title {
			float: left;
			width: 100%;
			font-size: 15px; 
			font-weight: bold;
		}
		.card .text {
			float: left;
			width: 100%;
			margin-top: 15px;
			font-size: 12px; 
			font-weight: normal;
			line-height: 20px;
		}
			

}