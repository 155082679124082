.page.content--portfolio {
	margin-top:70px;
}

	.item {
		float:left;
		width:100%;
		margin-top:30px;
		margin-bottom:30px;
		cursor: url("../img/static/cursor-onhover.png"), pointer;	
	}
	
		.item .image {
			float:left;
			position:relative;
			width:100%;
			height:300px;
			background-color:#e1e1e1;
			background-repeat:no-repeat;
			background-size:cover;
			background-position:center;
			border-radius:5px;
			box-shadow:0 0 10px rgba(0,0,0,0.2);
			overflow:hidden;
		}
		
				.item:hover .image-overlay {
					opacity:0.8;
				}
				
				.item .image-overlay {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-color:#531b66;
					opacity:0;
					z-index:100;
					transition:all 0.2s;
					
				}
		
		.item .info {
			clear:both;
			float:left;
			width:100%;
		}
		
			.item .info h2  {
				clear:both;
				float:left;
				font-family:'Open Sans',arial;
				font-size:25px;
				font-weight:bold;
				margin:0;
				padding:0;
				margin-top:5px;
				color:#000;
			}
			
			.item .info h3  {
				clear:both;
				float:left;
				font-family:'Open Sans',arial;
				font-size:15px;
				margin:0;
				padding:0;
				margin-top:10px;
				font-weight:normal;
				color:#000;
			}



@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	

	.item {
		margin-top:15px;
		margin-bottom:15px;
	}
	
	.item .info h2 {
		font-size:20px;
	}
	
	.item .image {
		height:190px;
	}


}