.modal--error {
	position:absolute;
	width:290px;
	z-index:10000;
	background-color:#fff;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
}

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {	
  
}