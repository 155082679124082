footer {
	float:left;
	width:100%;
	background-color:#531b66;
}

	footer .top {
		clear:both;
		float:left;
		width:100%;
		height:80px;
		background-image:url("../img/static/footer-top-bckgrnd.jpg");
		background-size:cover;
		background-repeat:no-repeat;
		backround-position:center;
	}
	
	footer .middle {
		clear:both;
		position:relative;
		float:left;
		width:100%;
		margin-top:40px;
		margin-bottom:80px;
	}
	
		footer .middle .logo {
			float:left;
			width:300px;
			height:150px;
			background-image: url("../img/static/footer-logo.png");
			background-size:contain;
			background-repeat:no-repeat;
		}
		
		footer .middle h2 {
			font-size:'Open Sans',arial;
			font-size:18px;
			font-weight:bold;
			line-height:20px;
		}
		
			footer .middle h2 a {
				color:#fff;
			}
		
		footer .middle .spacer {
			position:absolute;
			right:0;
			width:2px;
			height:100%;
			background-color:rgba(0,0,0,0.2);
		}
		
		footer .middle .padding {
			padding-left:20px;
		}
		
		footer .middle ul {
			list-style-type: none;
			padding:0;
		}
		
			footer .middle ul li {
				color:#fff;
				line-height:30px;
			}
			
				footer .middle ul li a { 
					color:#fff;
					text-decoration:none;
				}
				
				footer .middle ul li a:hover {
					text-decoration:underline;
				}
				
				footer .middle ul li a.active {
					font-weight:bold;
				}
			
			footer .middle ul.portfolio li {
				display:inline-block;
				float:left;
				width:130px;
			}
			
		footer .middle .button-1 {
			float:right;
			margin-top:20px;
		}
		
		footer .middle .eyes {
			right:0;
			top:0px;
			position:absolute;
			width:100%;
			height:400px;
			overflow:hidden;
		}

			footer .middle .eyes .eye {
				position:absolute;
				width:10vw;
				height:10vw;
				background-size:contain;
			}

				footer .middle .eyes .eye.eye-1 {
					right:40px;
					top:00px;
					transform:rotate(120deg);
					background-image:url("../img/static/eye-animate-1-white.gif");
				}

				footer .middle .eyes .eye.eye-2 {
					right:210px;
					top:200px;
					background-image:url("../img/static/eye-animate-2-white.gif");
				}

	footer .bottom {
		clear:both;
		float:left;
		padding:20px;
		font-size:13px;
		font-family:'Open Sans',arial;
		width:100%;
		background-color:#2b1832;
	}
	
		footer .bottom .copyright .date {
			float:left;
		}
	
		footer .bottom .copyright .name {
			float:left;
			margin-left:20px;
			font-weight:bold;
		}
	
			footer .bottom .copyright .name span {
				color:#f26866;
			}
		
		footer .bottom a {
			float:right;
			margin-left:20px;
			font-size:13px;
			font-family:'Open Sans',arial;
			color:#fff;
			text-decoration: none;
		}
		
			footer .bottom a:hover {
				text-decoration:underline;
			}


/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
	
	footer .middle .logo {
		width: 190px;
		height: 100px;
	}
	
	footer .middle .button-1 {
		float:left;
		margin-top:0;
	}
	
	footer .middle .eyes .eye {
		height:50px;
		width:50px;
	}
	
		footer .middle .eyes .eye.eye-2 {
			right: 110px;
		}
		
	
	footer .middle .padding {
		padding-left:0;
	}
	
	footer .bottom a {
		clear:both;
		margin-left:0;
		margin-top:10px;
		float:left;
		width:100%;
	}
	
}