.popup--language {
	width:400px;
}

	.popup--language .header {
		float:left;
		padding:10px;
		padding-left:20px;
		padding-right:20px;
		background-color:#2b1832;
		text-align:left;
		color:#fff;
	}
	
		.popup--language .header h2 {
			color:#fff;
			padding:0;
			margin:0;
		}

	.popup--language .content {
		float:left;
		background-color:#fff;
		padding:20px;
		text-align:left;
	}
	
	.popup--language a {
		float:left;
	}
	
		.popup--language a.active {
			font-weight:bold;
		}
		
	.popup--language .spacer {
		float:left;
		margin-left:10px;
		margin-right:10px;
	}
		
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {
	
	.popup--language {
		width:280px;
	}
	
}