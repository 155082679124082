.loader-tour {
	display:none;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:1000;
	background-color:#fff;
}

	.loader-tour iframe {
		float:left;
		width:100vw;
		height:100vh;
	}

	.loader-tour-exit {
		position:fixed;
		left:-10px;
		top:-55px;
		z-index:1100;
	}
	
		.loader-tour-exit span {
			float:left;
			padding:15px;
			padding-top:18px;
			padding-left:20px;
			padding-right:20px;
			background-color:#000;
			border-radius:5px;
			font-family:'Open sans',arial;
			font-size:16px;
			font-weight:bold;
			color:#fff;
			cursor:pointer;
		}
		
	

/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	
	
}
