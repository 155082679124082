.page.content--wowproduct {
		margin-top:70px;
}

	.page.content--wowproduct .producten-options {
		float:left;
		width:100%;
		background-color:#efefef;
		border-radius:10px;
	}
	
		.page.content--wowproduct .producten-options .item {
			float:left;
			width:100%;
			padding:40px;
			margin:0;
			cursor:default;
		}
	
@media (max-width: 1200px) {
	
}

@media (max-width: 992px) {
	
}

@media (max-width: 768px) {	

	.page.content--wowproduct .producten-options .item {
		padding:20px;
	}


}