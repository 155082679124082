header {
	position:absolute;
	width:100%;
	padding:40px;
}

	header .logo {
		display:inline-block;
		width:80%;
		height:179px;
		/*
		background-image:url("../img/static/logo.svg");
		background-size:contain;
		background-repeat: no-repeat; 
		*/
	}
	
		header .logo video {
			float:left;
			height:100%;
			width:100%;
		}
	
	header .bt-changelanguage {
		float:left;
		margin-top:13px;
		font-size:15px;
		font-weight:bold;
		font-family:'Open Sans',arial;
		text-transform: uppercase;
		color:#ccc;
		cursor:pointer;
	}
	
	header .bt-interesse {
		float:right;
		margin-left:40px;
		background-color: green;
		border-bottom: 3px solid #000;
	}

	header .button-1 {
		float:right;
		margin-left:40px;
	}
	
	header .menu-button {
		position:fixed;
		right:40px;
		top:37px;
		width:50px;
		height:50px;
		margin-left:20px;
		border-radius:50%;
		background-color:#531b66;
		box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
		cursor:pointer;
		z-index:500;
	}
	
		header .menu-button .row {
			position:absolute;
			height:4px;
			width:30px;
			background-color:#fff;
			cursor:pointer;
			transition:0.4s all;
		}
	
			header .menu-button .row.row-1 {
				top:15px;
				left:25px;
			}
			header .menu-button .row.row-2 {
				top:23px;
				left:25px;
			}
			header .menu-button .row.row-3 {
				top:31px;
				left:25px;
			}
			
		header .menu-button.active {
		}
		
			header .menu-button.active .row.row-1 {
				transform:rotate(45deg);
				top:23px;
				left:25px;
			}
			header .menu-button.active .row.row-2 {
				display:none;
			}
			header .menu-button.active .row.row-3 {
				transform:rotate(-45deg);
				top:23px;
				left:25px;
			}
		
	/*
	Demo 360
	*/
	.demo-overlay {
		display:none;
		position:fixed;
		top:0;
		left:0;
		width:100vw;
		height:100vh;
		overflow:hidden;
		z-index:1000;
	}
	
		.demo-overlay iframe {
			width:100vw;
			height:100vh;
		}
		
		.demo-overlay .exit-bt {
			position:fixed;
			top:-100px;
			left:10px;
			border-radius:5px;
			font-size:14px;
			line-height:20px;
			padding:10px;
			padding-left:15px;
			padding-right:15px;
			padding-top:20px;
			margin-top:-10px;
			color:#fff;
			font-weight:bold;
			background-color:#000;
			cursor:pointer;
		}
	
	/*
	Active menu
	*/
	header .menu-bckgrnd {
		display:none;
		position:fixed;
		left:0;
		top:0;
		right:0;
		bottom:0;
		background-color:rgba(0,0,0,0.8);
		z-index:100;
	}
	
		header .menu-bckgrnd.active {
			display:block;
		}
	
	header .menu-overlay {
		position:fixed;
		top:-1400px;
		right:-1400px;
		width:1150px;
		height:1140px;
		border-radius:50%;
		background-color:#f26866;
		z-index:150;
		transition:0.4s all;
		box-shadow: 0 0 40px 4px rgba(0,0,0,0.4);
	}
	
		header .menu-overlay.active {
			display:block;
			top:-550px;
			right:-350px;
		}
		
		header .menu {
			position:absolute;
			bottom:100px;
			left:140px;
			width:100%;
		}
		
			header .menu .left {
				float:left;
				width:200px;
			}
			
				header .menu .left .logo {
					float:left;
					margin-top:30px;
					width:200px;
					height:160px;
					background-image:url("../img/static/logo-menu.png");
					background-repeat:no-repeat;
					background-size:contain;
				}
			
				header .menu .left .button-1 {
					float:right;
				}
				
					header .menu .left .button-1.js-bt-demo {
						margin-top:20px;
					}
			
			header .menu .right {
				float:left;
				margin-left:70px;
				width:300px;
			}
			
				header .menu .right ul {
					list-style-type: none;
					padding:0;
				}
				
					header .menu .right ul li a {
						font-size:40px;
						color:#fff;
						font-weight:bold;
						text-decoration: none;
						line-height:76px;
					}
					
						header .menu .right ul li a:hover {
							text-decoration:underline;
						}
					
				header .menu .language {
					clear:both;
					float:left;
					margin-top:20px;
					width:100%;
					margin-left:5px;
				}
				
					header .menu .language a {
						float:left;
						margin-left:10px;
						margin-right:10px;
						color:#fff;
						text-decoration:none;
					}
					
						header .menu .language a:hover {
							text-decoration:underline;
						}
						
						header .menu .language a.active {
							font-weight:bold;
						}
					
						header .menu .language a:first-child {
							margin-left:0;
						}
					
					header .menu .language .spacer {
						float:left;
					}
					
				header .menu .b2b {
					clear:both;
					float:left;
					width:100%;
					margin-left:5px;
				}
				
					header .menu .b2b a {
						float:left;
						color:#fff;
						text-decoration:none;
					}
					
						header .menu .b2b a:hover {
							text-decoration:underline;
						}
					
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */
/* RESIZES ============================================================================================= */

@media (max-width: 1450px) {
	
	header .bt-changelanguage {
		display:none;
	}
	header .bt-interesse {
		display:block;
		float:right;
		margin-left:10px;
	}
	
	header .js-bt-demo {
		float:right;
	}
	
	header .logo {
		background-image:url("../img/static/logo.svg");
		background-size:contain;
		background-repeat: no-repeat;
		width:80px;
		height:80px;
	}
	
}

@media (max-width: 1366px) {
	
	header .logo {
		width:80px;
		height:80px;
	}
	
	header .bt-interesse {
		display:block;
		float:right;
	}
	
}

@media (max-width: 992px) {
	
	header .bt-interesse {
		display:block;
		float:right;
	}

}

@media (max-width: 768px) {
	
	header .bt-interesse {
		display:none;
	}
	
	header .js-bt-demo {
		display:none;
	}
	
	header {
		padding:10px;
	}
	
		header .logo {
			height:64px;
		}
		
		header .menu-button {
			top:17px;
			right:30px;
		}
		
	header .menu-overlay.active {
		top:-43px;
		right:-110px;
	}
	
		header .menu-overlay {
			width: 330px;
			height: 330px;
		}
		
		header .menu {
			bottom:50px;
			left:0;
		}
		
		header .menu .left .logo {
			display:none;
		}
		
		header .menu .right ul.list1 li {
			display:block;
			font-size: 25px;
			line-height: 23px;
		}
		
		header .menu .right ul li a {
			font-size:17px;
			line-height:17px;
		}
		
		header .menu-overlay .bt-interesse {
			display:block;
			position:absolute;
			left:-50px;
			top:109px;
			margin-left: 0;
		}
		
		header .menu-overlay .js-bt-demo {
			position:absolute;
			left:-65px;
			top:30px;
			margin-left: 0;
			display:block;
		}

		header .menu .language {
			margin-top: 16px;
		}


}